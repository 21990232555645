import React, { useRef, useEffect, useState, useLayoutEffect, useContext } from "react";

import style from "./style.module.less";
import { PageContext } from "./App";
export default function Footer (_props: { })  {

  return (
    <div
      
     
      className={" d-flex   justify-content-center align-items-center"}
    >
     <div className="cls-block-wrap flex-fill not-clip  relative footer">
       <div className="container">
            <div className="cls-block autoheight">
              <div className="text-center product_list ">
                <a target="_blank" href="https://justvoting.com" rel="noreferrer">
                  <img src={require("@src/images/product_icon_01.png")} alt="" />
                </a>
                <span></span>
               
                <a target="_blank" href="http://www.kcis.cn/" rel="noreferrer">
                  <img src={require("@src/images/product_icon_05.png")} alt="" />
                </a>
                <span></span>
                <a target="_blank" href="https://www.iptotem.com/" rel="noreferrer">
                  <img src={require("@src/images/product_icon_07.png")} alt="" />
                </a>
              </div>
              <div className="logos">
                <div className="company_icon">
                  <img src={require("@src/images/logo3.png")} alt="" />
                </div>
                <div className="social_icons">
                  <a className="hover-frame">
                    <img src={require("@src/images/wechat_icon.png")} alt="" />
                    <p>
                      <img src={require("@src/images/erweima.gif")} />
                    </p>
                  </a>
                  <a target="_blank" href="https://weibo.com/u/6180441117" rel="noreferrer">
                    <img src={require("@src/images/weibo_icon.png")} alt="" />
                  </a>
                </div>
              </div>
              <p className="copyright_text">© All Rights Reserved. 智媒云图 <span><span> 版权所有 020-87386049</span>
              <span>  <a target="_blank" className="link " href="http://beian.miit.gov.cn" rel="noreferrer" >粤ICP备18110983号</a></span></span> </p>
              <p className="copyright_text" style={{marginTop: 10}}><span>合作联系：020-87375779 <span>邮箱：<a style={{color:"#98d4ff"}} href="mailto:taozi@wonderina.com">taozi@wonderina.com</a></span></span></p>

            </div>
            </div>
          </div>
    </div>
  );
};

import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useContext,
  CSSProperties
} from "react";
import { motion, MotionValue, useScroll, useTransform } from "framer-motion";
import { PageContext } from "./App";
import useDebouncedState from "@restart/hooks/useDebouncedState";
export const Page1 = React.forwardRef < HTMLDivElement, { scrollYProgress:any, style?: React.CSSProperties }>((_props,ref) => {
  let { isMobile } = useContext(PageContext);
  const scrollYProgress = _props.scrollYProgress
  
  const translateY = useTransform(scrollYProgress, [0,0.5, 1], ["0%", "0%","-50%"]);
  let [, setStatus] = useDebouncedState(0, 50);
  useEffect(() => {
    setStatus(c => c + 1);
    return scrollYProgress.onChange((e) => {
      setStatus(c => c + 1);
    })
  }, [])
  let c = Math.round(scrollYProgress.get() * 10) / 10;

  return (
    <div
      ref={ref}
      style={{
        ..._props.style,
        
      }}
      className={" d-flex flex-column slide-foreground"}
    ><motion.div
      style={{
       
        backgroundImage: `linear-gradient(135deg, #EFF4FA 0%, #E2E9F1 100%)`,

      }}
      className={" d-flex flex-column slide-background"}
    >

      </motion.div>
      <motion.div className={"container flex-fill d-flex"} style={{  }}>
        <div
          className="cls-block-wrap not-clip flex-fill d-flex "
          
        >
          <section className={"cls-block animation " + [(c > 0) && "active",  (c === 0) && "remove"].filter(Boolean).join(" ")} >
            <div className="cls-menu-bar" >
              <a className="logo" href="#">
                <img
                
                  className="animated fadeInUp"
                  src={require("@src/images/logo2.png")}
                  alt=""
                />
              </a>
            </div>
            <div  
           
            className="flex-1 cls-flex justify-center" >
              <div className=" cls-sub-block animated fadeInUpShort delay-500ms">
                <div className="text left" >
                  <h4 className="animated fadeInUp delay-500ms">
                    我们致力于人工智能技术的普惠，
                    <br />
                    为行业+AI发展提供基于图像识别的核心算法定制
                  </h4>
                  <p className="animated fadeInUp delay-1000ms">
                    Wonder
                    How（欲知），基于图像模式识别，赋能算力领域，为垂直行业芯片制造商、整体解决方案供应商提供定制AI算法；通过深度学习及优化自研算法，不断扩充基于图像语义可识别、可理解、可转换、可推理的智力深度和广度，从专用智能逐步到通用智能，构建机器视觉中枢雏形。
                  </p>
                  <div className="cls-image-btn-wrap">
                    <a
                      href="http://wonderhow.intell-vision.com"
                      className="cls-image-btn animated fadeInUp delay-1500ms"
                    >
                      <img
                        src={require("@src/images/block1_btn.png")}
                        height="42"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div
                  className={
                    "right animated delay-500ms " +
                    [!isMobile && "fadeInLeft", isMobile && "fadeInUp"]
                      .filter(Boolean)
                      .join(" ")
                  }
                >
                  {!isMobile && (
                    <img
                      src={require("@src/images/block1.png")}
                      className="img1"
                      alt=""
                    />
                  )}
                  {isMobile && (
                    <img
                      src={require("@src/images/block1_bg.png")}
                      className="img1"
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </motion.div>
    </div>
  );
});

export default Page1;

export let Slide1BG = React.forwardRef((_props: { scrollYProgress:MotionValue, style?: React.CSSProperties }, ref) => {
  let { isMobile } = useContext(PageContext);
  const translateY = useTransform(_props.scrollYProgress, [0, 0.5, 1], ["100%", "0%", "-100%"])
  let c = _props.scrollYProgress;

  
  return (
    <motion.div
      style={{
        ..._props.style,
        backgroundImage: `linear-gradient(135deg, #EFF4FA 0%, #E2E9F1 100%)`,
       
         translateY
      }}
      className={" d-flex flex-column slide-background"}
    >
 
    </motion.div>
  );
});

import React, { useRef, useEffect, useState, useLayoutEffect, useContext } from "react";

import { motion, MotionValue, useScroll, useTransform } from "framer-motion";
import { PageContext } from "./App";
import useDebouncedState from "@restart/hooks/useDebouncedState";
export default   React.forwardRef<HTMLDivElement, { scrollYProgress: any, style?: React.CSSProperties }>((_props, ref) => {
  let { isMobile } = useContext(PageContext);
  const scrollYProgress = _props.scrollYProgress
  let [, setStatus] = useDebouncedState(0, 50);
  useEffect(() => {
    setStatus(c => c + 1);
    return scrollYProgress.onChange((e) => {
      setStatus(c => c + 1);
    })
  }, [])

  const translateY = useTransform(scrollYProgress, [0, 0.5, 1], ["0%", "0%", "-50%"]);
  let c = Math.round(scrollYProgress.get() * 10) / 10;

  return (
    <div
      ref={ref}
      style={{
        ..._props.style
      }}
      className={" part2 d-flex flex-column slide-foreground"}
    ><motion.div
      style={{
     
          backgroundImage: `linear-gradient(45deg, #D4C5C4 0%, #C7C8CB 100%)`,

      }}
      className={" d-flex flex-column slide-background"}
    >

      </motion.div>
      <motion.div  className={"container flex-fill d-flex"} style={{}}>
        <div
          className="cls-block-wrap  not-clip flex-fill d-flex style2"

        >
          <section className={"cls-block  animation " + [(c > 0) && "active", (c === 0 ) && "remove"].filter(Boolean).join(" ")} >
            <div className="cls-menu-bar">
              <a className="logo" href="#">
                <img
                  className="animated fadeInUp"
                  src={require("@src/images/logo4.png")}
                  alt=""
                />
              </a>
            </div>

            <div className="flex-1 cls-flex justify-center">
              <div className=" cls-sub-block animated fadeInUpShort  delay-500ms ">
                <div
                  className={
                    "left1 animated delay-500ms " +
                    [!isMobile && "fadeInRight", isMobile && "fadeInUp"]
                      .filter(Boolean)
                      .join(" ")
                  }
                >
                  <img
                    src={require("@src/images/block2.png")}
                    className="img1"
                    alt=""
                  />
                </div>
                <div className="text right1 ">
                  <h4 className="animated fadeInUp delay-500ms">
                    我们相信科技与艺术终有一日将在山顶汇合，
                    激发、点燃并支持每一个人的想象和创造
                  </h4>
                  <p className="animated fadeInUp delay-1000ms">
                    Wonderina（云图里），创新融合艺术、人文、AI三大核心元素，将艺术欣赏、人文体验通过智能技术完美结合和互动，带出无限创意、自由及个性化的生活艺术品牌，并为大众带来前所未有的线上线下感官体验。云图里目前拥有画家琥珀、摄影师查克等多位AI机器人艺术家。
                  </p>
                  <div className="cls-image-btn-wrap">
                    <a href="https://wonderina.com" className="cls-image-btn animated fadeInUp delay-1500ms">
                      <img src={require('@src/images/block2_btn.png')} height="42" /></a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </motion.div>
    </div>
  );
});


export let Slide2BG = React.forwardRef((_props: { scrollYProgress: MotionValue, style?: React.CSSProperties }, ref) => {
  let { isMobile } = useContext(PageContext);

  const translateY = useTransform(_props.scrollYProgress, [0, 0.5, 1], ["100%","0%", "-100%"])
  let c = _props.scrollYProgress;

  return (
    <motion.div
      style={{
        ..._props.style,
        translateY,
        backgroundImage: `linear-gradient(45deg, #D4C5C4 0%, #C7C8CB 100%)`,

      }}
      className={" d-flex flex-column slide-background"}
    >

    </motion.div>
  );
});
// These must be the first lines in src/index.js
//支持ie9及以上浏览器
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom/client";
import "@src/../node_modules/bootstrap/dist/css/bootstrap.min.css";
import App from "./App";

import * as serviceWorker from '@src/serviceWorker'

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <App></App>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
import React, { useRef, useEffect, useCallback, useState } from "react";
import Window, {
  WindowRef,
  useWindow,
  RouterConfig
} from "@components/window/index";
import Page1 from "./page1";
import ViewScroll from "@src/utils/view.scroll";
import $ from "jquery";
import "@src/App.less";
import Home from "./Home";
export let PageContext = React.createContext({} as { isMobile: boolean });
let routers: RouterConfig[] = [
  {
    path: "/",
    component: Page1
  }
];
function App() {
  let style = { height: window.innerHeight };
  let [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    
    function onresize() {
      let clientWidth = window.innerWidth;
      if (clientWidth <= 728) {
        isMobile = true;
      } else {
        isMobile = false;
      }
      setIsMobile(isMobile)
    }
    $(window).on("resize", onresize);
   
    onresize()
    return () => {
      $(window).off("resize", onresize);
    };
  }, []);
  
  return (
    <PageContext.Provider value={{ isMobile: isMobile }}>
      <div className={`container-fluid`}>
        <Home></Home>
      </div>
    </PageContext.Provider>
  );
}

export default App;

import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useReducer,
  useMemo
} from "react";
import style from "./style.module.less";
import Slide1, { Slide1BG } from "./page1";
import Slide2, { Slide2BG } from "./page2";
import Slide3, { Slide3BG } from "./page3";
import Slide4 from "./page4";
import SlideWrap from "./SlideWrap";
import useUpdatedRef from "@restart/hooks/useUpdatedRef";

let Home = _props => {
  let Swipers = [
    // { name: [, Slide4], height: 1, sticky: true, speed: 1 },
    { name: [, Slide1], height: 1, sticky: true, speed: 1 },
    { name: [, Slide2], height: 1, sticky: true, speed: 1 },
    { name: [, Slide3], height: 2, sticky: false, speed: 0 }
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const activeIndexRef=useUpdatedRef(activeIndex);
 
  useEffect(function () {
    function onScroll(e) {
      const scrollTop = window.scrollY||document.firstElementChild?.scrollTop || 0;
      const clientHeight = window.innerHeight||document.firstElementChild?.clientHeight || 1;
      const index = Math.round(scrollTop / clientHeight);
    
      if (index !== activeIndexRef.current) {
        setActiveIndex(Math.min(2,index));
      }
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  },[])
  return (
    <>
      <div className='row  align-items-center position-relative'>
        <div className='col p-0'>
          {Swipers.map((Comp, index) => {
            let Comps: any[] =
              Comp.name instanceof Array ? Comp.name : [Comp.name];
            return (
              <SlideWrap key={index} Comps={Comps} index={index}></SlideWrap>
            );
          })}
        </div>
        <div className={style.pagination}>
          {
            [0, 1, 2].map(item => {
              return (
                <span
                  className={item === activeIndex ? style.active : ''}
                ></span>
              );
            })
          }
        </div>
        <div className={style.arrow + ' d-sm-none'}>
          <img src={require('./images/箭头.png')} alt='' />
        </div>
      </div>
    </>
  );
};

export default Home;
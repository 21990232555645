import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useContext
} from "react";
import style from "./style.module.less";
import $ from "jquery";
import SwiperCore, { Mousewheel, Scrollbar, Navigation } from "swiper";
SwiperCore.use([Navigation]);
export default React.memo((_props: {}) => {
  let [swiperRef] = useState(React.createRef<HTMLDivElement>());
  let [prevRef] = useState(React.createRef<HTMLDivElement>());
  let [nextRef] = useState(React.createRef<HTMLDivElement>());
  useEffect(() => {
    let sw = new SwiperCore(swiperRef.current as any, {
      autoplay: false,
      loop: false, // 循环模式选项
      autoHeight: true,
      navigation: {
        prevEl: prevRef.current,
        nextEl: nextRef.current
      },
      on: {
        slideChangeTransitionStart: function(argument) {
          $(".cls-event-container .swiper-slide")
            .eq(this.activeIndex)
            .addClass("remove");
        },
        slideChangeTransitionEnd: function(argument) {
          $(".cls-event-container .swiper-slide")
            .eq(this.activeIndex)
            .removeClass("remove")
            .addClass("active");
        },
        init: function() {
          $(".cls-event-container .swiper-slide")
            .eq(this.activeIndex)
            .removeClass("remove")
            .addClass("active");
        }
      }
    });

    return () => {
      sw && sw.destroy();
    };
  }, [swiperRef]);

  return (
    <>
      <div className='cls-event-container relative'>
        <div className='cls-event-title absolute'>
          <h2></h2>
          <div className='cls-directive'>
            <div className='prev' ref={prevRef}>
              <img src={require('@src/images/arrow1.png')} alt='' />
            </div>
            <div className='next' ref={nextRef}>
              <img src={require('@src/images/arrow2.png')} alt='' />
            </div>
          </div>
        </div>

        <div
          className=' relative'
          ref={swiperRef}
          style={{ overflow: 'hidden' }}
        >
          <div className='swiper-wrapper'>
            <div className='swiper-slide animation'>
              <div className='cls-event-title '>
                <h2>大事记 2023</h2>
              </div>
              <div className='event-list'>
                <ul className='list-nostyle'>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp '>
                        <div className='lt'>1月</div>

                        <p>
                          云图里美术馆（Wonder
                          X）开馆，并推出概念展《复得返自然》；
                        </p>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div className='swiper-slide animation'>
              <div className='cls-event-title '>
                <h2>大事记 2022</h2>
              </div>
              <div className='event-list'>
                <ul className='list-nostyle'>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp '>
                        <div className='lt'>12月</div>

                        <p>
                          云图里1号区上线，基于web3打造数字世界，并发布首个数字身份智能合约；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-500ms'>
                        <div className='lt'>10月</div>

                        <p>
                          通过国家知识产权局复审，继续获评“国家知识产权优势企业”；
                        </p>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div className='swiper-slide animation'>
              <div className='cls-event-title '>
                <h2>大事记 2021</h2>
              </div>
              <div className='event-list'>
                <ul className='list-nostyle'>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp '>
                        <div className='lt'>12月</div>

                        <p>
                          边缘智能应用至电视终端，中标“AI骨骼追踪及虚拟人驱动产品开发项目”；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-500ms '>
                        <div className='lt'>09月</div>

                        <p>获比亚迪开发者创新大赛冠军；</p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-1000ms'>
                        <div className='lt'>07月</div>

                        <p>
                          与上海喵呜科技达成战略合作，共同拓展科教文领域应用，并联合参展第29届上海国际广印展（APPPEXPO）；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-1000ms'>
                        <div className='lt'>03月</div>

                        <p>
                          推出Wonder Musician趣味互动娱乐服务，在广州番禺时代E-Park开展线下体验活动；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-1500ms'>
                        <div className='lt'>02月</div>

                        <p>
                          推出“奇妙指挥家”体感装置，在广州CBD天德广场“奏响2021·奇妙音乐会”；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-2000ms'>
                        <div className='lt'>01月</div>

                        <p>推出“影子神探”在线娱乐应用，辩影识人；</p>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div className='swiper-slide animation'>
              <div className='cls-event-title '>
                <h2>大事记 2020</h2>
              </div>
              <div className='event-list'>
                <ul className='list-nostyle'>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp '>
                        <div className='lt'>12月</div>

                        <p>再次获评入选（2020年）广州市人工智能入库企业；</p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-500ms'>
                        <div className='lt'>06月</div>

                        <p>
                          发布科技艺术品牌“Wonderina”（云图里），聚焦科技与艺术的融合创造和交互体验；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp  delay-1000ms'>
                        <div className='lt'>05月</div>

                        <p>
                          与大股东南都传媒实施业务重组，完成新闻媒体业务的资产剥离；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp  delay-1500ms'>
                        <div className='lt'>04月</div>
                        <p>
                          4月3日，公司股票正式终止在全国中小企业股份转让系统（新三板）挂牌。
                        </p>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div className='swiper-slide animation'>
              <div className='cls-event-title '>
                <h2>大事记 2019</h2>
              </div>
              <div className='event-list'>
                <ul className='list-nostyle'>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp '>
                        <div className='lt'>12月</div>
                        <p>
                          12月26日，与中国社科院社会心理学研究中心在北京发布2019年《中国社会心态蓝皮书》，这是连续第五年与中国社科院研究团队联合发布社会心态蓝皮书。
                        </p>
                      </dd>
                      <dd className='animated fadeInUp delay-500ms'>
                        <p>
                          12月，入选“2019年度国家知识产权优势示范企业名单”，经国家知识产权局评审认定为“2019年度国家知识产权优势企业”。
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-1000ms'>
                        <div className='lt'>09月</div>
                        <p>9月，获评“2019年度广东省知识产权示范企业”。</p>
                      </dd>
                      <dd className='animated fadeInUp delay-1500ms'>
                        <p>
                          {' '}
                          9月19日，受邀参加CE
                          China广州国际电子消费品及家电品牌展，并发布新品：琥珀线绳画（Hopper
                          String
                          Art），由机器人绕线创作并支持用户定制的艺术装饰画。
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-2000ms'>
                        <div className='lt'>05月</div>
                        <p>
                          5月，机器人琥珀推出第二款周边硬件“机器人琥珀智能画架”（Hopper
                          E1），将AI创作能力植入普通画架，用户自助即可获机器臂一笔螺旋线画出的艺术肖像作品。
                        </p>
                      </dd>
                      <dd className='animated fadeInUp delay-2500ms'>
                        <p>
                          5月11日，通过国家知识产权贯标认证，并获得知识产权管理体系认证证书。
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-3000ms'>
                        <div className='lt'>02月</div>
                        <p>
                          2月27日，与中国社科院社会心理学研究中心联合发布《民众美好生活需要调查（2019）》；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-3500ms'>
                        <div className='lt'>01月</div>
                        <p>
                          1月，机器人琥珀正式上岗广州图书馆，成为广图的首个机器人馆员。公司与广州图书馆达成战略合作，联合共建“智慧图书馆应用研究基地”；
                        </p>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div className='swiper-slide animation'>
              <div className='cls-event-title '>
                <h2>大事记 2018</h2>
              </div>
              <div className='event-list'>
                <ul className='list-nostyle'>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp '>
                        <div className='lt'>12月</div>
                        <p>
                          12月12日，推出“Wonder
                          Shannon”（机器人小南）升级版，刷新定位为用户提供智能信息助理服务
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-500ms'>
                        <div className='lt'>11月</div>
                        <p>
                          11月7日，与广东哲力知识产权事务所联合推出“企图腾”，并正式亮相2018广东知识产权交易博览会，以“AI+IP”打造智慧知产新生态；
                        </p>
                      </dd>
                      <dd className='animated fadeInUp delay-1000ms'>
                        <p>
                          11月6日，机器人琥珀推出首款智能硬件“机器人琥珀儿童相机”（Hopper
                          C1），并获得IBDA 2018中国创新产品设计大奖；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-1500ms'>
                        <div className='lt'>06月</div>
                        <p>
                          6月30
                          日，以“机器人琥珀”为原型的亲子舞台剧《机器人奇遇记之绘梦机器人》（To
                          be Wonder）在广州大剧院成功首演；
                        </p>
                      </dd>
                      <dd className='animated fadeInUp delay-2000ms'>
                        <p>
                          6月14日，人工智能实验展示中心“Wonder
                          X”在广州市南沙新区正式开业；
                        </p>
                      </dd>
                      <dd className='animated fadeInUp delay-2500ms'>
                        <p>
                          6月7日，发布全国首个基于计算机视觉解决图书馆盘点的AI实体机器人产品“Wonder
                          Sheldon”（图书馆书童）；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-3000ms'>
                        <div className='lt'>04月</div>
                        <p>
                          4月26日，发布 AI 机器人“Wonder
                          Hopper”（机器人琥珀），并与广州大剧院达成战略合作，以
                          Hopper 为原型，联合打造全球首部原创 AI
                          机器人舞台剧《To be Wonder》；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-3500ms'>
                        <div className='lt'>02月</div>
                        <p>
                          2月1日，与好翫集市达成战略合作，联合打造“AI
                          集市”，研究探索人工智能在集市、商业综合体等零售业态的应用；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-4000ms'>
                        <div className='lt'>01月</div>
                        <p>
                          1月，与中国科学院华南植物园联合共建的“人工智能识别植物园区”面向公众开放体验；
                        </p>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
            <div className='swiper-slide animation'>
              <div className='cls-event-title '>
                <h2>大事记 2017</h2>
              </div>
              <div className='event-list'>
                <ul className='list-nostyle'>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp '>
                        <div className='lt'>12月</div>
                        <p>
                          12月19日，注册迁址，公司更名为“广东智媒云科技股份有限公司”；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-500ms'>
                        <div className='lt'>11月</div>
                        <p>
                          11月29日，基于图像识别学习算法，Wonder
                          Hopper（机器人琥珀）诞生于实验室；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-1000ms'>
                        <div className='lt'>08月</div>
                        <p>
                          8月29日，全资子公司广东数相智能科技有限公司、广东南都全媒体网络科技有限公司通过国家知识产权贯标认证，并获得知识产权管理体系认证证书。
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-1500ms'>
                        <div className='lt'>06月</div>
                        <p>
                          6月27日，全资子公司（珠海猫眼金经投资管理有限公司）获中国证券投资基金业协会登记为私募基金管理人，机构登记编码P1063312；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-2000ms'>
                        <div className='lt'>03月</div>
                        <p>3月20日，完成凯迪社区的资产剥离；</p>
                      </dd>
                      <dd className='animated fadeInUp delay-2500ms'>
                        <p>
                          3月3日，公司AI团队获得Kaggle国际猫狗识别比赛第七名，是唯一一支进入
                          Top10 的中国参赛团队；
                        </p>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dd className='animated fadeInUp delay-3000ms'>
                        <div className='lt'>01月</div>
                        <p>
                          1月18日，与北京大学计算机科学技术研究所联合成立“智媒体实验室”，共同开展基于自然语言处理的语义计算及智能信息服务研究；
                        </p>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='cls-event-img'>
        <img src={require('@src/images/block3_img.png')} alt='' />
      </div>
    </>
  );
});

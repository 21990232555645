import React, { useRef, useEffect, useCallback, useState, useMemo } from "react";
import {useScroll} from "framer-motion"

export default function Component({ Comps, index }: { Comps: any,index: number }) {
  let ref = useRef<HTMLDivElement>(null);
 
  const { scrollYProgress } = useScroll({ target: ref, offset: ["start end", index !== 2 ? "end start":"end end"] })

  return <>{Comps.map((Item, index1) => {
      let zIndex = (index) * Comps.length + index1 * 10;
    return <Item
      ref={ref}
      key={index1}
      scrollYProgress={scrollYProgress}
      style={{ zIndex }}
    /> 
      
  })}</>
}
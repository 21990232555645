import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useContext
} from "react";

import style from "./style.module.less";
import { PageContext } from "./App";
import $ from 'jquery';
import Footer from "./footer";
import SwiperCore, { Mousewheel, Scrollbar, Navigation } from "swiper";
import "swiper/less";
import "swiper/less/navigation";
import ComEvent from "./ComEvent";
import useDebouncedState from "@restart/hooks/useDebouncedState";
import { motion, MotionValue, useTransform } from "framer-motion";
SwiperCore.use([Navigation]);
export default React.forwardRef<HTMLDivElement, { scrollYProgress: MotionValue, style?: React.CSSProperties }>((_props, ref) => {
  let { isMobile } = useContext(PageContext);
  const scrollYProgress = _props.scrollYProgress
  let [, setStatus] = useDebouncedState(0, 50);
  useEffect(() => {
    setStatus(c => c + 1);
    return scrollYProgress.onChange((e) => {
      setStatus(c => c + 1);
    })
  }, [])
  let c = Math.round(scrollYProgress.get()*10)/10;

  return (
    <div
      ref={ref}
      style={{ scrollSnapAlign: "start", ..._props.style,height:"auto",overflow:"hidden", backgroundImage: `linear-gradient(134deg , #6BACD5 0%, #C8C8CA 100%)` }}
      className={" d-flex flex-column slide-foreground"}
    >
      <div className={"container part3 flex-fill d-flex position-static"} style={{ }}>
        <div
          className={"cls-block-wrap flex-column not-clip flex-fill d-flex style3  "}
         
        >
          <section className={"cls-block autoheight  animation " + [(c > 0) && "active", (c === 0) && "remove"].filter(Boolean).join(" ")} >
            <div className="cls-flex">
              <div className="left2 text">
                <div className="cls-menu-bar visible">
                  <a className="logo" href="#">
                    <img
                      className="animated fadeInUp "
                      src={require("@src/images/logo3.png")}
                      alt=""
                    />
                  </a>
                </div>
                <h3 className="animated fadeInUp delay-500ms">
                  我们倡导正直、好奇的企业文化和价值观
                </h3>
                <p className="animated fadeInUp delay-1000ms">
                  广东智媒云图科技股份有限公司，前身为2000年成立的海南凯迪网络资讯有限公司，自2015年6月至2020年4月期间挂牌新三板，公司以技术为驱动，在人工智能和大数据领域拥有超过十年的技术积累，先后获得广州市首批人工智能入库企业、两高四新入库企业，广东省知识产权示范企业、国家知识产权优势企业等认定和称号。
                </p>
                <p className="animated fadeInUp delay-1500ms">
                  公司面向未来，坚持自主创新，在机器学习、图像模式识别领域拥有核心算法设计能力及底层框架定制基础，并已积累丰富的创新研究成果和场景应用原型方案，已申报及拥有超过300项专利（其中
                  75%为发明专利），是具备AI算法模型定制和原始创新能力的创新型科技企业。目前，公司专注图像识别、图像生成的核心技术研发及应用，重点打造赋能AI芯片的算法定制平台WonderHow、科技+艺术的融合体验品牌Wonderina。
                </p>
              </div>
              <div className="right img3 animated fadeInLeft">
                <img src={require("@src/images/block3.png")} alt="" />
              </div>
            </div>
          </section>
          <section className={"cls-block autoheight animated fadeInUp delay-2000ms " + [(c > 0 ) && "active", (c === 0) && "remove"].filter(Boolean).join(" ")} >
            <ComEvent></ComEvent>
          </section>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
});



export let Slide3BG = React.forwardRef((_props: { scrollYProgress: MotionValue, style?: React.CSSProperties },ref) => {
  let { isMobile } = useContext(PageContext);

  const translateY = useTransform(_props.scrollYProgress, [0,1], ["100vh","-100vh"])
  let c = _props.scrollYProgress;

  return (
    <motion.div
      style={{
        minHeight:"200vh",
        ..._props.style,
        backgroundImage: `linear-gradient(134deg , #6BACD5 0%, #C8C8CA 100%)`,
        translateY
      }}
      className={" d-flex flex-column slide-background"}
    >
     
    </motion.div>
  );
});